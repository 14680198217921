<template>
  <span :class="'text-' + textSize">
    <template v-if="freeShipping">{{ $t('price.shipping_free') }}</template>
    <template v-else>
      {{ $t('price.plus') }}
      <AsyncTooltip v-if="shippingInfoBoxPdp" :text="shippingInfoBoxPdp">
        <span>{{ $t('price.shipping_costs') }}</span>
      </AsyncTooltip>
      <template v-else>
        {{ $t('price.shipping_costs') }}
      </template>
    </template>
  </span>
</template>

<script setup lang="ts">
import type { Price as BapiPrice } from '@scayle/storefront-nuxt'
import { useServerPrefetch } from '~/composables/useServerPrefetch'
import Tooltip from '~/components/ui/popovers/Tooltip.vue'
import { useFimBasket } from '~/composables/useFimBasket'
import { useFeatureToggles } from '~/composables/useFeatureToggles'

const props = defineProps({
  price: {
    type: Object as PropType<BapiPrice>,
    required: true,
    default: () => {},
  },
  quantity: {
    type: Number as PropType<number>,
    required: false,
    default: 1,
  },
  productId: {
    type: Number as PropType<number>,
    required: false,
    default: 0,
  },
  showInfoBox: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  textSize: {
    type: String as PropType<string>,
    validator: (value: string) => ['xs', 'sm'].includes(value),
    default: 'sm',
  },
  isRx: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { data: config, fetch: fetchConfig } = await useCustomData()

useServerPrefetch(async () => {
  await fetchConfig()
})

const freeShippingConfig = computed(() => config.value?.freeShipping)
const { isRxFreeShippingEnabled } = await useFeatureToggles()
const basket = await useFimBasket()
const hasRxItemInBasket = computed(() =>
  basket.items.value?.find((item) => isRxMainItem(item)),
)
const freeShipping = computed(
  () =>
    (isRxFreeShippingEnabled() && (props.isRx || hasRxItemInBasket.value)) ||
    (freeShippingConfig.value &&
      props.price &&
      props.price.withTax * props.quantity >=
        freeShippingConfig.value.minBasketValue) ||
    freeShippingConfig.value?.productIds?.includes(props.productId),
)

const shippingInfoBoxPdp = computed(
  () => freeShippingConfig.value?.shippingInfoBoxPdp,
)
</script>
