export const useServerPrefetch = (fn: () => Promise<any>, key: string = '') => {
  const route = useRoute()

  const previousRoute = useState('use-server-prefetch-previous-route')
  const fetched = useState(
    `use-server-prefetch-${key}${key ? '-' : ''}${route.path}`,
    () => false,
  )

  onServerPrefetch(async () => {
    await fn()
    fetched.value = true
    /**
     * For some reason in the first call router doesn't include hash in fullPath
     * but does in subsequent ones, which results in different values for previousRoute
     * and redundant data fetching on client side. Excluding hash from fullPath solves the issue.
     */
    const pathWithoutHash = route?.fullPath?.replace(route.hash, '')
    previousRoute.value = pathWithoutHash
  })

  onBeforeMount(async () => {
    const pathWithoutHash = route?.fullPath?.replace(route.hash, '')
    if (!fetched.value || previousRoute.value !== pathWithoutHash) {
      await fn()
      previousRoute.value = pathWithoutHash
    }
  })
}
